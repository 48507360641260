import React from "react";
import Layout from "../components/Layout";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner" style={{backgroundImage: `url(../images/asesoria_legal.jpeg)`}}>
                        <h1>Asesoría Legal</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Defensa Fiscal, Litigio de Créditos Fiscales ante SAT e IMSS, Asesoría Corporativa
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>Contenido</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
